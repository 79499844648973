import { mapActions, mapGetters, mapMutations } from "vuex";
import { directive as onClickaway } from "vue-clickaway";

export default {
    name: "sub-page",
    data() {
        return {
            ingredient: null,
            webinarCourseTheme: null,
            crumbs: [],
            courseInBasket: [],
            openedAnnounce: {
                id: "",
            },
        };
    },
    directives: {
        onClickaway: onClickaway,
    },
    watch: {
        basket: function() {
            this.basket.forEach(product => {
                this.courseInBasket.push(product.id);
            });
        },
    },
    computed: {
        ...mapGetters({
            menu: "setting/headerMenu",
            basket: "basket/basket",
            pages: "pages/pages",
        }),
    },
    async created() {
        if (this.$route.query.type === "ingredient") {
            this.ingredient = await this.getIngredient(this.$route.params.slugIngredient);
        }
        if (this.$route.query.type === "webinar") {
            this.webinarCourseTheme = await this.getWebinarCourseTheme(this.$route.params.slugIngredient);

            this.basket.forEach(product => {
                this.courseInBasket.push(product.id);
            });
        }
    },
    methods: {
        ...mapActions({
            getIngredient: "pages/GET_INGREDIENT",
            getWebinarCourseTheme: "pages/GET_WEBINAR_THEME",
        }),
        ...mapMutations({
            addWebinarToBasket: "basket/ADD_WEBINAR_ITEM_TO_BASKET",
            changeBasketPopup: "popups/CHANGE_BASKET_POPUP",
            changeSeminarInfo: "popups/CHANGE_SEMINAR_INFO_POPUP",
        }),
        /**
         * @param {WebinarCourse} webinarCourse
         */
        InBasket(id) {
            return this.basket.find(product => product.id === id);
        },
        checkCoursesInBasket(id) {
            this.courseInBasket.push(id);
        },
        openAnnounce(id) {
            this.openedAnnounce.id = id;
        },
        away: function() {
            this.openedAnnounce.id = "";
        },
        goToProfile() {
            this.$router.push({ name: "platform" });
        },
        showSeminarInfoPopup(title, description) {
            this.changeSeminarInfo(true, { title: title, description: description });
        },
        handleWebinarToBasket({ product }) {
            if (!this.isCourseInBasket) {
                this.addWebinarToBasket({
                    select_count: 1,
                    product,
                    product_price: product.productPrices.data[0],
                });
                this.changeBasketPopup(true);
                this.checkCoursesInBasket(product.id);
                this.$toasted.success(this.$t("webinarWasSuccessfullyAddedToYourCart"));
            }
        },
    },
};
